import React, { useState, useEffect } from "react";
import "../style/Advertisement.css";
import ProfileAdvertisementsItem from "../items/profile_advertisements_item";
import { getUserAdd } from "../../../api/getuserad";
import { getCategories } from "../../../api/getCategories";

import searchIcon from '../../../assets/icons/search.svg';
import NoUserAd from "../items/no_user_ad";
import Dropdown from "../../../common/components/Dropdown";

import Icon from "../../../common/components/Icon";
import DropdownCategory from "../../../common/components/DropdownCategory";

import { t } from "i18next";

const statusMapping = {
    "Активные": "active",
    "Ожидающие": "pending",
    "Неактивные": "inactive",
    "Отклоненные": "rejected"
};

function Advertisements({ setActiveTab }) {
    const [filter, setFilter] = useState("Активные");
    const [adsData, setAdsData] = useState([]);
    const [filteredAdsData, setFilteredAdsData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [counts, setCounts] = useState({
        active: 0,
        pending: 0,
        inactive: 0,
        rejected: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [sort, setSort] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                const categoryOptions = data.map(category => ({
                    label: category.name,
                    value: category.id,
                    subcategories: category.subcategories,
                }));
                setCategories(categoryOptions);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchCounts = async () => {
            setLoading(true);
            setError(null);
            try {
                const statuses = Object.values(statusMapping);
                const countsData = {};
                for (const status of statuses) {
                    const data = await getUserAdd(status);
                    countsData[status] = data.items.length;
                }
                setCounts(countsData);
            } catch (error) {
                setError('Ошибка загрузки данных');
            } finally {
                setLoading(false);
            }
        };

        fetchCounts();
    }, []);

    useEffect(() => {
        const fetchAds = async () => {
            setLoading(true);
            setError(null);
            try {
                const status = statusMapping[filter];
                const data = await getUserAdd(status);
                setAdsData(data.items);
                setFilteredAdsData(data.items);
            } catch (error) {
                setError('Ошибка загрузки данных');
            } finally {
                setLoading(false);
            }
        };

        fetchAds();
    }, [filter]);

    const handleBackClick = () => {
        setActiveTab(null);
    };

    const handleSortChange = (selectedOption) => {
        let sortedData = [...filteredAdsData];

        switch (selectedOption) {
            case t('dateDesSort'):
                sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case t('dateAscSort'):
                sortedData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                break;
            case t('priceAscSort'):
                sortedData.sort((a, b) => a.price - b.price);
                break;
            case t('priceDesSort'):
                sortedData.sort((a, b) => b.price - a.price);
                break;
            default:
                break;
        }

        setFilteredAdsData(sortedData);
        setSort(selectedOption);
    };

    const handleSearchChange = (event) => {
        const text = event.target.value.toLowerCase();
        setSearchText(text);

        const filteredData = adsData.filter(ad =>
            ad.title.toLowerCase().includes(text) ||
            ad.description.toLowerCase().includes(text)
        );

        setFilteredAdsData(filteredData);
    };

    const getNoUserAdText = () => {
        switch (filter) {
            case "Активные":
                return {
                    headerText: "Активные объявления",
                    contentText: "Активные объявления появляются здесь с момента активации.",
                };
            case "Ожидающие":
                return {
                    headerText: "Ожидающие объявления",
                    contentText: "Перед активацией объявления проходят модерацию.",
                };
            case "Неактивные":
                return {
                    headerText: "Неактивные объявления",
                    contentText: "Неактивные объявления можно активировать заново.",
                };
            case "Отклоненные":
                return {
                    headerText: "Отклоненные объявления",
                    contentText: "Объявления попадают сюда, если заявка не прошла модерацию",
                };
            default:
                return {
                    headerText: "Объявления",
                    contentText: "Нет доступных объявлений для отображения.",
                };
        }
    };

    return (
        <div className="profile_render_container">
            <div className="menu_right_section_header">
                <Icon src="backicon" onClick={handleBackClick} className={"back_icon_mobile"} />
                <h2 className="menu_right_section_header_text">{t('announcements')}</h2>
                <div className="filters">
                    <button
                        className={filter === "Активные" ? "active" : ""}
                        onClick={() => setFilter("Активные")}
                    >
                        {t('active')} <span className="chat_filter_count">{counts.active || 0}</span>
                    </button>
                    <button
                        className={filter === "Ожидающие" ? "active" : ""}
                        onClick={() => setFilter("Ожидающие")}
                    >
                        {t('pending')} <span className="chat_filter_count">{counts.pending || 0}</span>
                    </button>
                    <button
                        className={filter === "Неактивные" ? "active" : ""}
                        onClick={() => setFilter("Неактивные")}
                    >
                        {t('inactive')} <span className="chat_filter_count">{counts.inactive || 0}</span>
                    </button>
                    <button
                        className={filter === "Отклоненные" ? "active" : ""}
                        onClick={() => setFilter("Отклоненные")}
                    >
                        {t('rejected')} <span className="chat_filter_count">{counts.rejected || 0}</span>
                    </button>
                </div>
            </div>
            <div className="controls_content">
                <div className="input_container">
                    <img className='search_icon_input' src={searchIcon} alt='search_icon' />
                    <input
                        className="second_section_search"
                        placeholder={t('searchPlaceholder')}
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="controls">
                    <DropdownCategory
                        text={t('category')}
                        options={categories}
                        selected={null}
                        style="s256"
                    />
                    <Dropdown
                        text={t('sortBy')}
                        options={[
                            t('dateDesSort'),
                            t('dateAscSort'),
                            t('priceAscSort'),
                            t('priceDesSort')
                        ]}
                        selected={sort || t('defaultSort')}
                        onOptionSelect={handleSortChange}
                        style="s256"
                    />
                </div>
            </div>
            {loading && <div></div>}
            <ul className="menu_right_section_content ads_list">
                {filteredAdsData.length > 0 ? (
                    filteredAdsData.map((ad) => (
                        <ProfileAdvertisementsItem key={ad.id} item={ad} />
                    ))
                ) : (
                    <NoUserAd {...getNoUserAdText()} />
                )}
            </ul>
        </div>
    );
}

export default Advertisements;